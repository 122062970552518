const map = require('lodash.map');
const inventory = require('.');

function pdpStoreTemplate(store) {
    return [
        `<li class="store-list-item ${store.storeId === User.storeId ? ' selected' : ''}">`,
        `    <div class="store-address">${store.address1}, ${store.city} ${store.stateCode
        } ${store.postalCode}</div>`,
        `    <div class="store-status" data-status="${store.statusclass}">${store.status}</div>`,
        '</li>',
    ].join('\n');
}
function pdpStoresListingTemplate(stores) {
    if (stores && stores.length) {
        return [
            '<div class="store-list-pdp-container">',
            (stores.length > 1 ? `    <a class="stores-toggle collapsed" href="#">${Resources.SEE_MORE}</a>` : ''),
            '    <ul class="store-list-pdp">',
            map(stores, pdpStoreTemplate).join('\n'),
            '    </ul>',
            '</div>',
        ].join('\n');
    }
    return '';
}

function storesListing(stores) {
    // list all stores on PDP page
    if ($('.store-list-pdp-container').length) {
        $('.store-list-pdp-container').remove();
    }
    $('.availability-results').append(pdpStoresListingTemplate(stores));
}

const productInventory = {
    setPreferredStore(storeId) {
        User.storeId = storeId;
        $.ajax({
            url: Urls.setPreferredStore,
            type: 'POST',
            data: {storeId},
        });
    },
    productSelectStore() {
        const self = this;
        inventory.getStoresInventory(this.pid).then((stores) => {
            inventory.selectStoreDialog({
                stores,
                selectedStoreId: User.storeId,
                selectedStoreText: Resources.PREFERRED_STORE,
                continueCallback: storesListing,
                selectStoreCallback: self.setPreferredStore,
            });
        }).done();
    },
    init() {
        const $availabilityContainer = $('.availability-results');
        const self = this;
        this.pid = $('input[name="pid"]').val();

        $('#product-content .set-preferred-store').on('click', (event) => {
            event.preventDefault();
            if (!User.zip) {
                inventory.zipPrompt(() => {
                    self.productSelectStore();
                });
            } else {
                self.productSelectStore();
            }
        });

        if ($availabilityContainer.length) {
            if (User.storeId) {
                inventory.getStoresInventory(this.pid).then(storesListing);
            }

            // See more or less stores in the listing
            $availabilityContainer.on('click', '.stores-toggle', (event) => {
                event.preventDefault();
                $('.store-list-pdp .store-list-item').toggleClass('visible');
                if ($(event.currentTarget).hasClass('collapsed')) {
                    $(event.currentTarget).text(Resources.SEE_LESS);
                } else {
                    $(event.currentTarget).text(Resources.SEE_MORE);
                }
                $(event.currentTarget).toggleClass('collapsed');
            });
        }
    },
};

module.exports = productInventory;
