const ajax = require('../../ajax');
const tooltip = require('../../tooltip');
const util = require('../../util');

module.exports = () => {
    const $addToCart = $('#add-to-cart');
    const $addAllToCart = $('#add-all-to-cart');
    const $productSetList = $('#product-set-list');

    const updateAddToCartButtons = () => {
        if ($productSetList.find('.add-to-cart-disabled').length > 0) {
            $addAllToCart.attr('disabled', 'disabled');
            // product set does not have an add-to-cart button, but product bundle does
            $addToCart.attr('disabled', 'disabled');
        } else {
            $addAllToCart.removeAttr('disabled');
            $addToCart.removeAttr('disabled');
        }
    };

    const updateAddAllToCartButton = () => {
        if ($productSetList.find('.product-set-item form').not('.suppressed').length === 0) {
            $addAllToCart.attr('disabled', 'disabled');
        } else if ($productSetList.find('.add-to-cart-disabled').length === 0) {
            $addAllToCart.removeAttr('disabled');
        }
    };

    const suppressForm = (el) => {
        const qtyVal = el.val();
        const parentForm = el.parents('form');
        if (qtyVal < 1 || qtyVal === '') {
            parentForm.addClass('suppressed');
        } else {
            parentForm.removeClass('suppressed');
        }
        updateAddAllToCartButton();
    };

    if ($productSetList.length > 0) {
        updateAddToCartButtons();
    }
    // click on swatch for product set
    $productSetList.on('click', '.product-set-item .swatchanchor', (e) => {
        e.preventDefault();
        if ($(e.currentTarget).parents('li').hasClass('unselectable')) { return; }
        const url = Urls.getSetItem + e.currentTarget.search;
        const $container = $(e.currentTarget).closest('.product-set-item');
        const qty = $container.find('form input[name="Quantity"], form select[name="Quantity"]').first().val();

        const ratingHtml = $container.find('.rating-wrapper').html();

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', Number.isNaN(qty) ? '1' : qty),
            target: $container,
            callback() {
                updateAddToCartButtons();
                tooltip.init();
                const newQtyField = $container.find('form input[name="Quantity"], form select[name="Quantity"]').first();
                suppressForm(newQtyField);
                if (ratingHtml) {
                    $container.find('.rating-wrapper').html(ratingHtml);
                }
            },
        });
    // handle selects for product sets
    }).on('change', '.product-set-item .variation-select', (e) => {
        e.preventDefault();
        const selectedOptionURL = $(e.currentTarget).val();
        const selectedOptionURLtrim = selectedOptionURL.indexOf('?');
        const variationValue = selectedOptionURL.substring(selectedOptionURLtrim);
        const url = Urls.getSetItem + variationValue;
        const $container = $(e.currentTarget).closest('.product-set-item');
        const qty = $container.find('form input[name="Quantity"], form select[name="Quantity"]').first().val();

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', Number.isNaN(qty) ? '1' : qty),
            target: $container,
            callback() {
                updateAddToCartButtons();
                tooltip.init();
                const newQtyField = $container.find('form input[name="Quantity"], form select[name="Quantity"]').first();
                suppressForm(newQtyField);
            },
        });
    }).on('change', '.product-set-item input[name="Quantity"]', (e) => {
        suppressForm($(e.currentTarget));
    }).on('click', '.add-to-cart', (e) => {
        const parentForm = $(e.currentTarget).parents('.product-add-to-cart');
        if (parentForm.hasClass('suppressed')) {
            parentForm.removeClass('suppressed');
            updateAddAllToCartButton();
        }
    });

    /* global pwr */
    if (SitePreferences.ENABLE_POWER_REVIEWS) {
        window.pwr = window.pwr || function initPwr(...args) {
            (pwr.q = pwr.q || []).push(args);
        };

        const snippets = $('.ps-review-snippet');

        if (snippets.length > 0) {
            const prArray = [];
            snippets.each((idx, snippet) => {
                const $snippet = $(snippet);
                prArray.push({
                    ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
                    api_key: SitePreferences.PR_API_KEY,
                    locale: SitePreferences.PR_LOCALE,
                    merchant_group_id: SitePreferences.PR_MERCHANT_GROUP_ID,
                    merchant_id: SitePreferences.PR_MERCHANT_ID,
                    page_id: $snippet.data('page-id'),
                    components: {
                        CategorySnippet: snippet.id,
                    },
                });
            });

            pwr('render', prArray);
            const targetNode = document.getElementById('product-set-list');

            const observer = new MutationObserver((mutationList) => {
                mutationList.forEach((mutation) => {
                    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                        const $links = $(mutation.target).find('.pr-no-reviews').parents('.rating-wrapper').find('.links');
                        if ($links.length > 0) {
                            $($links.children()[0]).hide();
                            $($links.children()[1]).html(Resources.WRITE_FIRST_REVIEW);
                        }
                    }
                });
            });

            observer.observe(targetNode, {childList: true, subtree: true});
        }
    }
};
