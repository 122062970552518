const forEach = require('lodash.foreach');
const map = require('lodash.map');
const TPromise = require('promise');
const dialog = require('../dialog');
const util = require('../util');

function storeTemplate(store, selectedStoreId, selectedStoreText) {
    return [
        `<li class="store-tile ${store.storeId}${store.storeId === selectedStoreId ? ' selected' : ''}">`,
        '    <p class="store-address">',
        `        ${store.address1}<br/>`,
        `        ${store.city}, ${store.stateCode} ${store.postalCode}`,
        '    </p>',
        `    <p class="store-status" data-status="${store.statusclass}">${store.status}</p>`,
        `    <button class="select-store-button" data-store-id="${store.storeId}"${
            store.statusclass !== 'store-in-stock' ? 'disabled="disabled"' : ''}>`,
        `        ${store.storeId === selectedStoreId ? selectedStoreText : Resources.SELECT_STORE}`,
        '    </button>',
        '</li>',
    ].join('\n');
}

function storeListTemplate(stores, selectedStoreId, selectedStoreText) {
    if (stores && stores.length) {
        return [
            '<div class="store-list-container">',
            '<ul class="store-list">',
            map(stores, (store) => storeTemplate(store, selectedStoreId, selectedStoreText)).join('\n'),
            '</ul>',
            '</div>',
            '<div class="store-list-pagination">',
            '</div>',
        ].join('\n');
    }
    return `<div class="no-results">${Resources.INVALID_ZIP}</div>`;
}

function zipPromptTemplate() {
    return [
        '<div id="preferred-store-panel">',
        `    <input type="text" id="user-zip" placeholder="${Resources.ENTER_ZIP}" name="zipCode"/>`,
        '</div>',
    ].join('\n');
}

/**
 * @description test whether zipcode is valid for either US or Canada
 * @return {Boolean} true if the zipcode is valid for either country, false if it's invalid for both
 * */
function validateZipCode(zipCode) {
    const regexes = {
        canada: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
        usa: /^\d{5}(-\d{4})?$/,
    };
    let valid = false;
    if (!zipCode) { return false; }
    forEach(regexes, (re) => {
        const regexp = new RegExp(re);
        valid = regexp.test(zipCode);
    });
    return valid;
}

const storeinventory = {
    zipPrompt(callback) {
        const self = this;
        dialog.open({
            html: zipPromptTemplate(),
            options: {
                title: Resources.STORE_NEAR_YOU,
                width: 500,
                buttons: [{
                    text: Resources.SEARCH,
                    click() {
                        const zipCode = $('#user-zip').val();
                        if (validateZipCode(zipCode)) {
                            self.setUserZip(zipCode);
                            if (callback) {
                                callback(zipCode);
                            }
                        }
                    },
                }],
                open() {
                    $('#user-zip').on('keypress', (e) => {
                        if (e.which === 13) {
                            // trigger the search button
                            $('.ui-dialog-buttonset .ui-button').trigger('click');
                        }
                    });
                },
            },
        });
    },
    getStoresInventory(pid) {
        return TPromise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, {
                pid,
                zipCode: User.zip,
            }),
            dataType: 'json',
        }));
    },
    /**
     * @description open the dialog to select store
     * @param {Array} options.stores
     * @param {String} options.selectedStoreId
     * @param {String} options.selectedStoreText
     * @param {Function} options.continueCallback
     * @param {Function} options.selectStoreCallback
     * */
    selectStoreDialog(options) {
        const self = this;
        const {stores} = options;
        const {selectedStoreId} = options;
        const {selectedStoreText} = options;
        const storeList = storeListTemplate(stores, selectedStoreId, selectedStoreText);
        dialog.open({
            html: storeList,
            options: {
                title: `${Resources.SELECT_STORE} - ${User.zip}`,
                buttons: [{
                    text: Resources.CHANGE_LOCATION,
                    click() {
                        self.setUserZip(null);
                        // trigger the event to start the process all over again
                        $('.set-preferred-store').trigger('click');
                    },
                }, {
                    text: Resources.CONTINUE,
                    click() {
                        if (options.continueCallback) {
                            options.continueCallback(stores);
                        }
                        dialog.close();
                    },
                }],
                open() {
                    $('.select-store-button').on('click', (event) => {
                        event.preventDefault();
                        const storeId = $(event.currentTarget).data('storeId');
                        // if the store is already selected, don't select again
                        if (storeId === selectedStoreId) { return; }
                        $('.store-list .store-tile.selected').removeClass('selected')
                            .find('.select-store-button').text(Resources.SELECT_STORE);
                        $(event.currentTarget).text(selectedStoreText)
                            .closest('.store-tile').addClass('selected');
                        if (options.selectStoreCallback) {
                            options.selectStoreCallback(storeId);
                        }
                    });
                },
            },
        });
    },
    setUserZip(zip) {
        User.zip = zip;
        $.ajax({
            type: 'POST',
            url: Urls.setZipCode,
            data: {
                zipCode: zip,
            },
        });
    },
    shippingLoad() {
        const $checkoutForm = $('.address');
        $checkoutForm.off('click');
        $checkoutForm.on('click', 'input[name$="_shippingAddress_isGift"]', (event) => {
            $(event.currentTarget).parent().siblings('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val());
        });
    },
};

module.exports = storeinventory;
