const ajax = require('../../ajax');
const image = require('./image');
const progress = require('../../progress');
const productStoreInventory = require('../../storeinventory/product');
const tooltip = require('../../tooltip');
const util = require('../../util');
const validator = require('../../validator');

/**
 * @description restore form values when product content is updated (Personalization and Pick 'n Choose)
 * @param {Array} formdata - array with name/value objects
 * @param {String} eventType - the type of event to trigger on the updated field
 * */
function restoreFormValues(formdata, eventType) {
    $.each(formdata, (i, val) => {
        if (val.value !== '' && $(`#${val.name}`).length > 0) {
            // Update value and trigger event for validation/update
            $(`#${val.name}`).val(val.value).trigger(eventType);
        }
    });
}

/**
 * @function
 * @desc Replaces the PDP header with the updated variation information
 */
function replaceHeader() {
    const $newHeading = $('.update-product-content-header');
    const $targetHeading = $('.product-col-1');

    if ($newHeading.length && $targetHeading.length) {
        $targetHeading.html($newHeading.html());
        $newHeading.remove();
    }
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 * */
function updateContent(href) {
    const $pdpForm = $('.pdpForm');
    const qty = $pdpForm.find('input[name="Quantity"], select[name="Quantity"]').first().val();
    const params = {
        Quantity: Number.isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val(),
    };
    // Save personalized values before refreshing content
    const personalizationValues = $('.personalization-options input, .personalization-options select').serializeArray();
    const pncOldPackSize = $('.pnc').data('packcount');
    const pncValues = $('.pnc input').serializeArray();
    const showMore = $('a.show-more').is(':hidden');

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback() {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }

            // Replace the PDP images
            image.replaceImages();
            // Replace the PDP heading content (name, id, price)
            replaceHeader();
            // Re-init the tooltips
            tooltip.init();
            if ($('#product-content').length > 0) {
                image.heroCarousel();
                image.wistiaThumbmail();
            }
            if (personalizationValues.length > 0) {
                // Re-init the validator
                validator.init();
                // Restore personalization values
                restoreFormValues(personalizationValues, 'blur');
                // Show extra options if 'Show More Options' has already been clicked
                if (showMore) {
                    $('a.show-more').click();
                }
            }
            // Restore Pick 'n Choose quantities
            if (pncValues.length > 0) {
                const pncNewPackSize = $('.pnc').data('packcount');
                // Only restore quantities if moving to a larger pack size
                if (parseInt(pncNewPackSize, 10) > parseInt(pncOldPackSize, 10)) {
                    restoreFormValues(pncValues, 'change');
                    // Trigger one more change because the 'X of X items selected' text doesn't consistently update
                    $('input.pnc-qty').change();
                }
            }
        },
    });
}

module.exports = () => {
    const $pdpMain = $('#pdpMain');

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', (e) => {
        e.preventDefault();
        if ($(e.currentTarget).parents('li').hasClass('unselectable')) { return; }
        updateContent(e.currentTarget.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', (e) => {
        const $productSetList = $('#product-set-list');
        if ($(e.currentTarget).val().length === 0) { return; }
        if ($productSetList.length === 0) {
            updateContent($(e.currentTarget).val());
        }
    });
};
