const compareWidget = require('../compare-widget');
const productTile = require('../product-tile');
const util = require('../util');

/**
 * @function
 * @desc Loads a new search page based on the grid URL on the place holder
 * @param loadingPlaceHolder {jQuery} Selector used to find the unloaded infinite scroll placeholder
 * @returns
 */
function loadSearchPage(loadingPlaceHolder) {
    // get url hidden in DOM
    const gridUrl = loadingPlaceHolder.attr('data-grid-url');

    // switch state to 'loading'
    // - switches state, so the above selector is only matching once
    // - shows loading indicator
    loadingPlaceHolder.attr('data-loading-state', 'loading');
    loadingPlaceHolder.addClass('infinite-scroll-loading');

    // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
    const fillEndlessScrollChunk = (html) => {
        loadingPlaceHolder.removeClass('infinite-scroll-loading');
        loadingPlaceHolder.attr('data-loading-state', 'loaded');
        $('.search-result-content').append(html);
    };
    $.ajax({
        type: 'GET',
        dataType: 'html',
        url: gridUrl,
        success(response) {
            // update UI
            fillEndlessScrollChunk(response);
            productTile.init();
        },
    });
}

/**
 * @function
 * @desc Loads a new page of the infinite scroll if an unloaded placeholder is found
 * @returns
 */
function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    const loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        loadSearchPage(loadingPlaceHolder);
    }
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    const $main = $('#main');
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', (event) => {
        event.preventDefault();
        const cb = $(event.currentTarget);
        const tile = cb.closest('.product-tile');
        const func = event.currentTarget.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        const itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb,
            colorval: tile.find('.swatch.selected').data('colorval') || '',
        });
    });

    // handle toggle refinement blocks
    $main.on('click', '.refinement > a.toggle, .refinement > h3', (e) => {
        e.preventDefault();

        if (!$('.search-refinements-section').length > 0) {
            $(e.currentTarget).toggleClass('expanded').attr(
                'aria-expanded',
                $(e.currentTarget).attr('aria-expanded') === 'false' ? 'true' : 'false',
            );
        }
    }).on('click', '.refinement .clear', (e) => {
        e.stopPropagation();
    }).on('click', '.refinement .see-more', (e) => {
        e.preventDefault();
        $(e.currentTarget).parents('ul').find('li').removeClass('hidden');
        $(e.currentTarget).parents('.see-more-ref').addClass('hidden');
    });

    if ($(window).innerWidth() >= util.getViewports('lg')) {
        $('.refinement > h3').slice(1, 4).addClass('expanded')
            .attr('aria-expanded', 'true');
    }
    util.smartResize(() => {
        if ($(window).innerWidth() >= util.getViewports('lg')) {
            $('.refinement > h3').slice(1, 4).addClass('expanded')
                .attr('aria-expanded', 'true');
        } else {
            $('.refinement > h3').slice(1).removeClass('expanded')
                .attr('aria-expanded', 'false');
        }
    });

    // handle events for updating grid
    $main.on('click', '.refinements a:not(.see-more), .pagination a, .breadcrumb-refinement-value a, .selected-refinement-value a, .clear-all-refinements', (e) => {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(e.currentTarget).parents('.category-refinement').length > 0 || $(e.currentTarget).parents('.folder-refinement').length > 0 || $(e.currentTarget).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();
        if ($(e.currentTarget).hasClass('clear-all-refinements')) {
            const queryString = util.getQueryString(window.location.href);
            const params = util.getQueryStringParams(queryString);
            let newURL = window.location.href.split('?')[0];

            // Remove all refinements. Keep any other parameter (q, cgid, srule, etc)
            $.each(params, (key, value) => {
                if (key.indexOf('pref') === -1 && key.indexOf('pmax') === -1 && key.indexOf('pmin') === -1) {
                    newURL = util.appendParamToURL(newURL, key, value);
                }
            });

            $(e.currentTarget).attr('href', newURL);
        }

        let focusElement = $(e.currentTarget).attr('id');

        if (focusElement) {
            focusElement = `#${focusElement}`;
        }

        if ($('#secondary').hasClass('open')) {
            $('#wrapper').addClass('open-refinements');
        } else {
            $('#wrapper').removeClass('open-refinements');
        }

        window.location.href = e.currentTarget.href;

        if ($(e.currentTarget).parents('.pagination')) {
            $('html,body').animate({
                scrollTop: 0,
            }, 700);
        }
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not(#quickviewbutton)', (e) => {
        const a = $(e.currentTarget);
        // get current page refinement values
        const wl = window.location;
        const qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        const hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        const params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        const tile = a.closest('.product-tile');
        const idx = tile.data('idx') ? +tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', (e) => {
        e.preventDefault();
        window.location.href = $(e.currentTarget).find('option:selected').val();
    }).on('change', '.items-per-page select', (e) => {
        const refineUrl = $(e.currentTarget).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            window.location.href = refineUrl;
        }
    });

    // Close Refinements on tab out
    $('.clear-all-refinements').focusout(() => {
        if ($(window).innerWidth() < util.getViewports('lg')) {
            $('#secondary.refinements').hide();
        }
    });

    // Close Refinements on escape keyup
    $(document).keyup((e) => {
        if (e.keyCode === 27) {
            if ($(window).innerWidth() < util.getViewports('lg')) {
                $('#secondary.refinements').hide();
                $('#wrapper').removeClass('open-refinements');
            }
        }
    });

    /**
     * @listener
     * @desc Listen for the click event on the refinement section toggle
     */
    $('body').on('click', '.refinement-select, .refinements-btn-close', () => {
        $('.refinements').toggle('slide', {direction: 'down'}, 300)
            .toggleClass('open');
        $('#wrapper').toggleClass('open-refinements');
    });

    $('body').on('change', '.sr-only.refinement-checkbox', (e) => {
        const href = $(e.currentTarget).data('href');
        if (typeof href === 'string' && href.length > 0) {
            window.location = href;
        }
    });

    /**
     * @listener
     * @desc Listen for the click event on the infinite scroll loader link
     */
    $('body').on('click', '.infinite-scroll-loader', (e) => {
        loadSearchPage($(e.currentTarget));
    });

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(window).on('scroll', infiniteScroll);
    }
    $('.recommendations-container .tiles-container').slick({
        speed: 300,
        dots: false,
        arrows: true,
        slide: '.grid-tile',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 690,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
    // In case the content is not ready on load helper function will set a mutation observer to reinit
    util.initDynamicCarousel('[id^="cq_recomm_slot"]', '.recommendations-container .tiles-container', 0);
}

/**
 * Update the position of the bottom-cat-banner slot to outside the area that will be replaced by the refirment ajax call
 */
function positionBVBottomSlot() {
    const $bottomcCatBanner = $('#bottom-cat-banner-container');
    if ($bottomcCatBanner.length > 0) {
        $bottomcCatBanner.insertAfter('#main');
        $bottomcCatBanner.show();
    }
}
positionBVBottomSlot();

module.exports = {
    init() {
        compareWidget.init();
        productTile.init();
        initializeEvents();
    },
};
