const dialog = require('./dialog');
const product = require('./pages/product');
const util = require('./util');
const validator = require('./validator');

function makeUrl(url, source, productListID) {
    let newUrl = url;
    if (source) {
        newUrl = util.appendParamToURL(newUrl, 'source', source);
    }
    if (productListID) {
        newUrl = util.appendParamToURL(newUrl, 'productlistid', productListID);
    }
    return newUrl;
}

const quickview = {
    init() {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
    },

    setup() {
        product.initializeEvents();
        // Initialize validation for personalization
        validator.init();
        // Setup personalization fields
        $('.personalization-option input, .personalization-option select').each((index, element) => {
            if ($(element).val() !== '') {
                $(element).blur();
                if ($(element).parents('.form-row').hasClass('visually-hidden')) {
                    $('a.show-more').click();
                }
            }
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show(options) {
        if (!this.exists()) {
            this.init();
        }
        const url = makeUrl(options.url, options.source, options.productlistid);
        const self = this;
        dialog.open({
            target: self.$container,
            url,
            options: {
                width: 950,
                dialogClass: 'no-title',
                open() {
                    self.setup(url);
                    if (typeof options.callback === 'function') { options.callback(); }
                },
            },
        });
    },

    exists() {
        return this.$container && this.$container.length > 0;
    },
};

module.exports = quickview;
