const dialog = require('../../dialog');
const tooltip = require('../../tooltip');
const util = require('../../util');
const addToCart = require('./addToCart');
const availability = require('./availability');
const image = require('./image');
const productNav = require('./productNav');
const productSet = require('./productSet');
const variant = require('./variant');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    tooltip.init();
    $('.recommendations-container .tiles-container').slick({
        speed: 300,
        dots: false,
        arrows: true,
        slide: '.grid-tile',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: util.getViewports('md'),
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 690,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    const $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant();
    image();
    productSet();
    util.initDynamicCarousel('[id^="cq_recomm_slot"]', '.recommendations-container .tiles-container', 0);
    util.initDynamicCarousel('[id^="cq_recomm_slot"]', '.recommendations-container .tiles-container', 1);

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', (e) => {
        const data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        const url = util.appendParamsToUrl(e.currentTarget.href, data);
        e.currentTarget.setAttribute('href', url);
    });

    $pdpMain.on('click', '#ask-a-question-link', (event) => {
        const $askAQuestionLink = $(event.target);

        const $quickViewDialog = $askAQuestionLink.closest('#QuickViewDialog');
        const $fullPdpLinkWithHashParam = $('.pdp-linkto-questions');
        if ($quickViewDialog.length && $fullPdpLinkWithHashParam.length) {
            $fullPdpLinkWithHashParam[0].click();
            return;
        }

        const $prQuestionBtn = $('#pr-question-form-link');
        $('.tab.questions .tab-header').addClass('expanded');

        $prQuestionBtn.trigger('click');
        if ($prQuestionBtn.offset().top) {
            $('html, body').animate({
                scrollTop: ($prQuestionBtn.offset().top),
            }, 200);
        }
    });

    // product options
    $pdpMain.on('change', '.product-options select', (e) => {
        const salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        const selectedItem = $(e.currentTarget).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', (e) => {
        e.preventDefault();
    });

    // convert personalization fields to all caps that require uppercase
    $pdpMain.on('blur', '.personalization-input.uppercase', (e) => {
        $(e.currentTarget).val($(e.currentTarget).val().toUpperCase());
    });

    // show hidden personalization fields when the 'Show More Options' link is clicked
    $pdpMain.on('click', '.show-more', (e) => {
        e.preventDefault();
        $('.personalization-option.visually-hidden').removeClass('visually-hidden');
        $(e.currentTarget).hide();
    });

    // show hidden optional personalization fields when the checkbox is checked
    $pdpMain.on('click', '#personalized-check', (e) => {
        if ($(e.currentTarget).is(':checked')) {
            $(e.currentTarget).closest('.personalization-box').find('.optional-personalization').css('display', 'block');
            $pdpMain.find('.swatchanchor').each((index, element) => {
                element.setAttribute('href', util.appendParamsToUrl(element.href, {personalizedChecked: 'true'}));
            });
        } else {
            $(e.currentTarget).closest('.personalization-box').find('.optional-personalization').css('display', 'none');
            $pdpMain.find('.swatchanchor').each((index, element) => {
                element.setAttribute('href', util.removeParamFromURL(element.href, 'personalizedChecked'));
            });
        }
    });

    // dynamic max quantity values for pick n choose products
    $pdpMain.on('change', 'input.pnc-qty', () => {
        const packCount = parseInt($('.pnc').data('packcount'), 10);
        let totalQty = 0;
        // calculate the total quantity of pick n choose items
        $('input.pnc-qty').each((index, element) => {
            totalQty += parseInt($(element).val(), 10);
        });
        // set the new max quantity based on quantity remaining in the selected pack
        const remainingQty = packCount - totalQty;
        $('input.pnc-qty:not(.disabled)').each((index, element) => {
            const maxAmount = parseInt($(element).val(), 10) + remainingQty;
            const maxAvailable = parseInt($(element).data('available'), 10);
            // make sure max is not more than the amount available
            $(element).prop('max', maxAmount > maxAvailable ? maxAvailable : maxAmount);
        });
        if (totalQty <= packCount && totalQty >= 0) {
            $pdpMain.find('.pnc-remain-count').text(totalQty);
        }
    });

    $('.size-chart-link a').on('click', (e) => {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
        });
    });

    $pdpMain.on('click', '.tab-header', (e) => {
        $(e.currentTarget).toggleClass('expanded');
    }).on('click', '.open-dialog', (e) => {
        e.preventDefault();
        dialog.open({
            url: $(e.currentTarget).attr('href'),
            doClose: $('.pt_product-search-result').length === 0,
            options: {
                title: $(e.currentTarget).find('.visually-hidden').text() || $(e.currentTarget).attr('title'),
            },
        });
    }).on('click', '.restrictions-dialog', (e) => {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            doClose: $('.pt_product-search-result').length === 0,
            options: {
                width: 540,
                title: $(e.target).attr('title'),
                dialogClass: 'restrictions-dialog',
            },
        });
    });

    // Pick 'n Choose
    const pncAttribute = $('ul.colpackcount');
    if (pncAttribute.length > 0) {
        // Trigger Pick 'n Choose quantity inputs to reset max quantity
        $pdpMain.find('.pnc-qty').trigger('change');
    }
}

const product = {
    initializeEvents,
    init() {
        initializeDom();
        initializeEvents();
    },
};

module.exports = product;
