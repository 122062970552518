const ajax = require('../../ajax');
const util = require('../../util');

/**
 * @description loads product's navigation
 * */
module.exports = () => {
    const $pidInput = $('.pdpForm input[name="pid"]').last();
    const $navContainer = $('#product-nav-container');
    // if no hash exists, or no pid exists, or nav container does not exist, return
    if (window.location.hash.length <= 1 || $pidInput.length === 0 || $navContainer.length === 0) {
        return;
    }

    const pid = $pidInput.val();
    const hash = window.location.hash.substr(1);
    const url = util.appendParamToURL(`${Urls.productNav}?${hash}`, 'pid', pid);

    ajax.load({
        url,
        target: $navContainer,
    });
};
