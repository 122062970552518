const quickview = require('./quickview');
const util = require('./util');

function initDataLayer() {
    /* global utag, utag_data */
    utag.view(utag_data);
}

/**
 * @function
 * @description Sets up the quick view button when called with the appropriate values
 * @param {jQuery} $link - Link that is used to define the quick view button
 * @param {boolean} focus - Mark this as true when the focus event is used
 */
function initQuickView($link, focus) {
    let $qvButton = $('#quickviewbutton');

    if ($qvButton.length) {
        $qvButton.remove();
    }

    if ($link.parents('.product-tile').hasClass('disable-quickview')) {
        return;
    }

    $qvButton = $(`<a id="quickviewbutton" class="quickview">${Resources.QUICK_VIEW}${util.svg('qv-icon')}</a>`);

    $qvButton.on('click', (e) => {
        e.preventDefault();
        quickview.show({
            url: $link.attr('href'),
            source: 'quickview',
            callback: initDataLayer,
        });
    });

    $qvButton.attr({
        href: $link.attr('href'),
        title: $link.attr('title'),
    });

    if (focus) {
        $qvButton.appendTo($link.parent('.product-image'));
        $qvButton.css('display', 'block');
    } else {
        $qvButton.appendTo($link.parent('.product-image'));
    }
}

/**
 * @function
 * @description Sets up the product tile so that when the mouse cursor enters the tile the quick view button appears
 */
function initQuickViewButtons() {
    $('.tiles-container .product-image').on('mouseenter', (event) => {
        const $link = $(event.currentTarget).find('.thumb-link');
        initQuickView($link);
    });
}

/**
 * @function
 * @description Sets up the product tile so that when the tile is focused with the keyboard the quick view button appears
 */
function initQuickViewButtonsFocus() {
    $('.tiles-container .product-image .thumb-link').on('focus', (event) => {
        const $link = $(event.currentTarget);
        initQuickView($link, true);
    });
}

function initRollover() {
    $('.tiles-container').on('mouseenter', '.product-image.rollover', (event) => {
        $(event.currentTarget).addClass('hover');
    }).on('mouseleave', '.product-image.rollover', (event) => {
        $(event.currentTarget).removeClass('hover');
    });
}
function syncTilesHeight() {
    const productTile = $('.tiles-container').find('.product-tile');
    let syncTiles = false;
    let inRow;
    if ($(window).innerWidth() >= util.getViewports('md')) {
        inRow = 3;
        syncTiles = true;
    } else if ($(window).innerWidth() >= util.getViewports('sm')) {
        inRow = 2;
        syncTiles = true;
    }

    productTile.find('.product-swatches, .product-promo, .product-pricing').removeAttr('style');
    if (syncTiles && productTile.length > 1) {
        for (let i = 0; i < productTile.length; i += inRow) {
            const swatchesArrayChunk = productTile.find('.product-swatches').slice(i, i + inRow);
            const promosArrayChunk = productTile.find('.product-promo').slice(i, i + inRow);
            const pricesArrayChunk = productTile.find('.product-pricing').slice(i, i + inRow);

            swatchesArrayChunk.syncHeight();
            promosArrayChunk.syncHeight();
            pricesArrayChunk.syncHeight();
        }
    }
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    initQuickViewButtons();
    initQuickViewButtonsFocus();
    initRollover();
    syncTilesHeight();

    /* global pwr */
    if (SitePreferences.ENABLE_POWER_REVIEWS) {
        window.pwr = window.pwr || function initPwr(...args) {
            (pwr.q = pwr.q || []).push(args);
        };

        const snippets = $('.pr-tile-review-snippet');

        if (snippets.length > 0) {
            const prArray = [];
            snippets.each((idx, snippet) => {
                const $snippet = $(snippet);
                prArray.push({
                    ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
                    api_key: SitePreferences.PR_API_KEY,
                    locale: SitePreferences.PR_LOCALE,
                    merchant_group_id: SitePreferences.PR_MERCHANT_GROUP_ID,
                    merchant_id: SitePreferences.PR_MERCHANT_ID,
                    page_id: $snippet.data('page-id'),
                    components: {
                        CategorySnippet: snippet.id,
                    },
                });

                const targetNode = snippet;

                /**
                 * Funky code alert: PR will only add the review snippet to one element per
                 * product ID, but for some searches including product sets, multiple results
                 * use the same ID.  This mutation observer watches for PR rendering the snippet
                 * and copies this snippet to all elements with the same product ID.
                 */
                const observer = new MutationObserver((mutationList) => {
                    mutationList.forEach((mutation) => {
                        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                            const $targetNode = $(targetNode);
                            const pageId = $targetNode.attr('data-page-id');
                            if ($targetNode.find('section').length > 0) {
                                observer.disconnect();
                                $(`[data-page-id=${pageId}]`).html($targetNode.html());
                            }
                        }
                    });
                });

                observer.observe(targetNode, {childList: true, subtree: true});
            });

            pwr('render', prArray);
        }
    }

    util.smartResize(() => {
        syncTilesHeight();
    });

    $('.swatch-list').on('mouseleave', (event) => {
        // Restore current thumb image
        const $tile = $(event.currentTarget).closest('.product-tile');
        const $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        const data = $thumb.data('current');
        if (data !== undefined) {
            $thumb.attr({
                src: data.src,
                alt: data.alt,
                title: data.title,
            });
        }
    });

    $('.swatch-list .swatch').on('click', (event) => {
        event.preventDefault();
        if ($(event.currentTarget).hasClass('selected')) { return; }

        const $tile = $(event.currentTarget).closest('.product-tile');
        $(event.currentTarget).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(event.currentTarget).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(event.currentTarget).attr('href'));
        $tile.find('name-link').attr('href', $(event.currentTarget).attr('href'));
        const data = $(event.currentTarget).children('img').filter(':first').data('thumb');
        const altData = $(event.currentTarget).children('img').filter(':first').data('thumbalt');
        const $tileImg = $tile.find('.product-image');
        const $thumb = $tileImg.find('.thumb-link .thumb-front');
        const $thumbAlt = $tileImg.find('.thumb-link .thumb-back');
        const currentAttrs = {
            src: data.src,
            alt: data.alt,
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
        if ($thumbAlt.length > 0 && altData) {
            const currentBackAttrs = {
                src: altData.src,
                alt: altData.alt,
            };
            $thumbAlt.attr(currentBackAttrs);
            $tileImg.addClass('rollover');
        } else if (altData) {
            $(`<img class="thumb-back" src="${altData.src}" alt="${altData.alt}" />`).appendTo($tileImg.find('.thumb-link'));
            $tileImg.addClass('rollover');
        } else {
            $tileImg.removeClass('rollover');
        }
    }).on('mouseenter', (event) => {
        // get current thumb details
        const $tile = $(event.currentTarget).closest('.product-tile');
        const $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        const data = $(event.currentTarget).children('img').filter(':first').data('thumb');
        const current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
        });
    });
}

module.exports = {
    init() {
        const $tiles = $('.tiles-container .product-tile');
        if ($tiles.length === 0) { return; }
        initializeEvents();
    },
};
