const TPromise = require('promise');
const page = require('./page');
const util = require('./util');
const dialog = require('./dialog');

let currentCategory = '';
const MAX_ACTIVE = 3;

/**
 * @private
 * @function
 * @description Verifies the number of elements in the compare container and updates it with sequential classes for ui targeting
 */
function refreshContainer() {
    const $compareContainer = $('.compare-items');
    const $compareItems = $compareContainer.find('.compare-item');
    const numActive = $compareItems.filter('.active').length;

    if (numActive < 2) {
        $('#compare-items-button').attr('disabled', 'disabled');
    } else {
        $('#compare-items-button').removeAttr('disabled');
    }

    $compareContainer.toggle(numActive > 0);
}
/**
 * @private
 * @function
 * @description Adds an item to the compare container and refreshes it
 */
function addToList(data) {
    // get the first compare-item not currently active
    const $item = $('.compare-items .compare-item').not('.active').first();
    const $productTile = $(`#${data.uuid}`);

    if ($item.length === 0) {
        if ($productTile.length > 0) {
            $productTile.find('.compare-check')[0].checked = false;
        }
        dialog.open({
            html: Resources.COMPARE_ADD_FAIL,
            options: {
                width: 530,
                buttons: [],
            },
        });
        return;
    }

    // if already added somehow, return
    if ($(`[data-uuid="${data.uuid}"]`).length > 0) {
        return;
    }
    // set as active item
    $item.addClass('active')
        .attr('data-uuid', data.uuid)
        .attr('data-itemid', data.itemid)
        .attr('data-colorval', data.colorval)
        .data('uuid', data.uuid)
        .data('itemid', data.itemid)
        .data('colorval', data.colorval)
        .append($(data.img).clone().addClass('compare-item-image'));
}
/**
 * @private
 * @function
 * description Removes an item from the compare container and refreshes it
 */
function removeFromList($item) {
    if ($item.length === 0) { return; }
    // remove class, data and id from item
    $item.removeClass('active').removeAttr('data-uuid').removeAttr('data-itemid').data('uuid', '').data('itemid', '').find('.compare-item-image').remove();
}

function addProductAjax(args) {
    const promise = new TPromise((resolve, reject) => {
        $.ajax({
            url: Urls.compareAdd,
            data: {
                pid: args.itemid,
                category: currentCategory,
            },
            dataType: 'json',
        }).done((response) => {
            if (!response || !response.success) {
                reject(new Error(Resources.COMPARE_ADD_FAIL));
            } else {
                resolve(response);
            }
        }).fail((jqxhr, status, err) => {
            reject(new Error(err));
        });
    });
    return promise;
}

function removeProductAjax(args) {
    const promise = new TPromise((resolve, reject) => {
        $.ajax({
            url: Urls.compareRemove,
            data: {
                pid: args.itemid,
                category: currentCategory,
            },
            dataType: 'json',
        }).done((response) => {
            if (!response || !response.success) {
                reject(new Error(Resources.COMPARE_REMOVE_FAIL));
            } else {
                resolve(response);
            }
        }).fail((jqxhr, status, err) => {
            reject(new Error(err));
        });
    });
    return promise;
}

function shiftImages() {
    return new TPromise((resolve) => {
        const $items = $('.compare-items .compare-item');
        $items.each((i, item) => {
            const $item = $(item);
            // last item
            if (i === $items.length - 1) {
                removeFromList($item);
                return;
            }
            const $next = $items.eq(i + 1);
            if ($next.hasClass('active')) {
                // remove its own image
                $next.find('.compare-item-image').detach().appendTo($item);
                $item.addClass('active')
                    .attr('data-uuid', $next.data('uuid'))
                    .attr('data-itemid', $next.data('itemid'))
                    .data('uuid', $next.data('uuid'))
                    .data('itemid', $next.data('itemid'));
            }
        });
        resolve();
    });
}

/**
 * @function
 * @description Removes product from the compare table
 * @param {object} args - the arguments object should have the following properties: itemid, uuid and cb (checkbox)
 */
function removeProduct(args) {
    const $cb = args.cb ? $(args.cb) : null;
    return removeProductAjax(args).then(() => {
        const $item = $(`[data-uuid="${args.uuid}"]`);
        removeFromList($item);
        if ($cb && $cb.length > 0) { $cb[0].checked = false; }
        refreshContainer();
    }, () => {
        if ($cb && $cb.length > 0) { $cb[0].checked = true; }
    });
}

function removeItem($item) {
    const uuid = $item.data('uuid');
    const $productTile = $(`#${uuid}`);
    return removeProduct({
        itemid: $item.data('itemid'),
        uuid,
        cb: ($productTile.length === 0) ? null : $productTile.find('.compare-check'),
    });
}

/**
 * @function
 * @description Adds product to the compare table
 */
// eslint-disable-next-line
function addProduct(args) {
    let promise;
    const $items = $('.compare-items .compare-item');
    const $cb = $(args.cb);
    const numActive = $items.filter('.active').length;
    if (numActive === MAX_ACTIVE) {
        let dontRemoveEl = true;
        dialog.open({
            html: Resources.COMPARE_CONFIRMATION,
            options: {
                width: 530,
                buttons: [{
                    text: Resources.REMOVE,
                    class: 'button primary',
                    click() {
                        // remove product using id
                        dontRemoveEl = false;
                        const $firstItem = $items.first();
                        promise = removeItem($firstItem).then(() => shiftImages());
                        $(this).dialog('close');
                        return promise.then(() => addProductAjax(args).then(() => {
                            addToList(args);
                            if ($cb && $cb.length > 0) { $cb[0].checked = true; }
                            refreshContainer();
                        })).then(null, () => {
                            if ($cb && $cb.length > 0) { $cb[0].checked = false; }
                        });
                    },
                }, {
                    text: Resources.NO_THANKS,
                    class: 'button tertiary',
                    click() {
                        $(this).dialog('close');
                    },
                }],
                close() {
                    if (dontRemoveEl) {
                        $cb[0].checked = false;
                    }
                },
            },
        });
    } else {
        promise = TPromise.resolve(0);
        return promise.then(() => addProductAjax(args).then(() => {
            addToList(args);
            if ($cb && $cb.length > 0) { $cb[0].checked = true; }
            refreshContainer();
        })).then(null, () => {
            if ($cb && $cb.length > 0) { $cb[0].checked = false; }
        });
    }
}

/**
 * @private
 * @function
 * @description Initializes the DOM-Object of the compare container
 */
function initializeDom() {
    const $compareContainer = $('.compare-items');
    currentCategory = $compareContainer.data('category') || '';
    const $active = $compareContainer.find('.compare-item').filter('.active');
    $active.each((index, element) => {
        const $productTile = $(`#${$(element).data('uuid')}`);
        if ($productTile.length === 0) { return; }
        $productTile.find('.compare-check')[0].checked = true;
    });
    // set container state
    refreshContainer();
}

/**
 * @private
 * @function
 * @description Initializes the events on the compare container
 */
function initializeEvents() {
    // add event to buttons to remove products
    $('.compare-item').on('click', '.compare-item-remove', (e) => {
        removeItem($(e.currentTarget).closest('.compare-item'));
    });

    // Button to go to compare page
    $('#compare-items-button').on('click', () => {
        const additionalPar = {category: currentCategory};
        $('.compare-items-panel .compare-item.active').each((index, element) => {
            additionalPar[`_itemid_${$(this).data('itemid')}`] = $(element).data('colorval');
        });
        page.redirect(util.appendParamsToUrl(Urls.compareShow, additionalPar));
    });

    // Button to clear all compared items
    // rely on refreshContainer to take care of hiding the container
    $('#clear-compared-items').on('click', () => {
        $('.compare-items .active').each((index, element) => {
            removeItem($(element));
        });
    });
}

exports.init = function init() {
    initializeDom();
    initializeEvents();
};

exports.addProduct = addProduct;
exports.removeProduct = removeProduct;
